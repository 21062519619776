import React from "react"
import styled from "@emotion/styled"

import Seo from "../components/seo"
import Layout from "../components/layout"

import LoadingIndicator from "../components/loading-indicator"
import { borders, shadows } from "../styles"

const PortalCard = styled.div`
  height: 450px;
  margin-top: 2.5rem;
  padding: 2rem 5rem;
  border-radius: 0;
  border: ${borders.standard};
  box-shadow: ${shadows.standard};
  background: white;
  overflow: hidden;

  @media screen and (min-width: 768px) {
    border-radius: 0.5rem;
  }
`

export default class StudentPortalPage extends React.Component {
  state = { isLoading: true }

  constructor(props) {
    super(props)
    this.pageStartRef = React.createRef()
  }

  componentDidMount() {
    if (!!window) {
      const tag = document.createElement("script")

      tag.type = "text/javascript"
      tag.src = "https://app.mymusicstaff.com/Widget/v2/Login.ashx"

      const anchor = document.getElementById("script-anchor")
      anchor.appendChild(tag)

      tag.onload = () => {
        this.setState({ isLoading: false })
      }
    }
  }

  render() {
    const { isLoading } = this.state
    return (
      <Layout>
        <Seo
          title="Student Portal | Sing Smart Studios | Townsville Singing School"
          keywords={[`student`, `portal`, `login`, `education`]}
        />
        <PortalCard>
          <h1 style={{ color: "black", textAlign: "center" }}>
            Student Portal
          </h1>
          <LoadingIndicator isLoading={isLoading} color={"black"} />
          <div id="script-anchor" />
        </PortalCard>
      </Layout>
    )
  }
}
